
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import {
    apiGameCheatsCategoryLists,
    apiGameCheatsCategoryDelete,
    apiGameCheatsCategoryIsShow
} from '@/api/setting'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import GameCategoryCheatsEdit from "@/components/game-category-cheats-edit.vue";

@Component({
    components: {
      GameCategoryCheatsEdit,
        LsDialog,
        LsPagination,
        ExportData,
    }
})
export default class CheatsCategoryManage extends Vue {
    /** S Data **/

    // 分页
    pager: RequestPaging = new RequestPaging()

    /** E Data **/

    /** S Methods **/

    // 更改显示开关状态
    changeStatus(value: 0 | 1, row: any) {
        apiGameCheatsCategoryIsShow({
            id: row.id,
            //aid: this.currentAppId,
        })
            .then(() => {
                this.getList()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 获取分类列表
    getList() {
        this.pager
            .request({
                callback: apiGameCheatsCategoryLists,
                params: {
                    //aid: this.currentAppId,
                },
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除该分类
    onCategoryDel(row: any) {
        apiGameCheatsCategoryDelete({
            id: row.id,
            //aid: this.currentAppId,
        }).then(() => {
            this.getList()
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    async created() {
        //await this.getGameAppList()
        this.loadData()
    }
    /** E Life Cycle **/

    loadData() {
        return Promise.all([
            this.getList(),
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }
}
